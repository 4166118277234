import {useEffect, useState} from 'react';
// @ts-ignore
import * as Plotly from 'plotly.js-dist';
import {Helmet} from 'react-helmet';
import MainLayout from './MainLayout';
// @ts-ignore
const {jStat} = require('jstat');

const Home = () => {
  const [controlSampleSize, setControlSampleSize] = useState<number>();
  const [controlSuccessRate, setControlSuccessRate] = useState<number>();
  const [testSampleSize, setTestSampleSize] = useState<number>();
  const [testSuccessRate, setTestSuccessRate] = useState<number>();
  const [confidence, setConfidence] = useState('0.90');
  const [pvalue, setPvalue] = useState();
  const zalpha2: any = {
    '0.90': 1.645,
    '0.95': 1.96,
    '0.98': 2.326,
    '0.99': 2.576,
  };
  const [confidenceInterval1, setConfidenceInterval1] = useState<number>();
  const [confidenceInterval2, setConfidenceInterval2] = useState<number>();

  const calculatePValue = async () => {
    const n1 = controlSampleSize ? controlSampleSize : 1;
    const n2 = testSampleSize ? testSampleSize : 1;
    const p1 = controlSuccessRate ? controlSuccessRate / 100 : 0;
    const p2 = testSuccessRate ? testSuccessRate / 100 : 0;

    try {
      setPvalue(jStat.fn.twoSidedDifferenceOfProportions(p1, n1, p2, n2));
    } catch (err) {
      setPvalue(undefined);
    }

    setConfidenceInterval1(
      zalpha2[confidence] * Math.sqrt((p1 * (1 - p1)) / n1),
    );
    setConfidenceInterval2(
      zalpha2[confidence] * Math.sqrt((p2 * (1 - p2)) / n2),
    );

    return Promise.resolve();
  };

  useEffect(() => {
    calculatePValue().then(() => {
      const dataControl = {
        x: [(controlSuccessRate ? controlSuccessRate : 0) / 100],
        y: ['Control'],
        error_x: {
          type: 'data',
          array: [confidenceInterval1],
        },
        type: 'scatter',
      };

      const dataTest = {
        x: [(testSuccessRate ? testSuccessRate : 0) / 100],
        y: ['Test'],
        error_x: {
          type: 'data',
          array: [confidenceInterval2],
        },
        type: 'scatter',
      };

      const layout = {
        xaxis: {
          range: [
            -0.05,
            (Math.max(
              testSuccessRate ? testSuccessRate : 0,
              controlSuccessRate ? controlSuccessRate : 0,
            ) /
              100 +
              Math.max(
                confidenceInterval1 ? confidenceInterval1 : 0,
                confidenceInterval2 ? confidenceInterval2 : 0,
              )) *
              1.15,
          ],
          tickformat: ',.0%',
          title: 'Conversion Rate',
          zeroline: false,
        },
        yaxis: {
          type: 'category',
          tickfont: {
            //size: 15,
          },
          ticklabeloverflow: 'allow',
          ticklabelposition: 'outside',
          showline: false,
          zeroline: false,
        },
        margin: {pad: 15, t: 25},
        showlegend: false,
      };

      Plotly.react('graphElement', [dataTest, dataControl], layout);
    });
  }, [
    controlSuccessRate,
    testSuccessRate,
    controlSampleSize,
    testSampleSize,
    confidence,
    confidenceInterval1,
    confidenceInterval2,
  ]);

  const body = () => (
    <>
      <div className="container" style={{paddingTop: 50}}>
        <Helmet>
          <title>
            Stat Sig Calculator: Experiments w/ Population Proportions
          </title>
        </Helmet>
        <div className="row" style={{paddingBottom: 20}}>
          <div className="col-12">
            <h1>Z-Test for Experiments with Proportions of Populations</h1>
            <h4>
              Use this template for testing the statistical significance between
              different experiment populations where the outcome metric you are
              measuring is a proportion of a population.
            </h4>
            <h6>Example: % of users who have made a purchase.</h6>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <div className="card" style={{height: 300}}>
              <div className="card-body">
                <h5 className="card-title">Data Input</h5>
                <div
                  className="row"
                  style={{
                    fontSize: 12,
                    textAlign: 'center',
                    paddingBottom: 10,
                  }}>
                  <div className="col-4"></div>
                  <div className="col-4">Number of Samples</div>
                  <div className="col-4">Success Rate</div>
                </div>
                <div className="row">
                  <div className="col-4">Control</div>
                  <div className="col-4">
                    <input
                      type="number"
                      className="form-control"
                      id="inputNumSamplesControl"
                      value={controlSampleSize}
                      onChange={e =>
                        setControlSampleSize(
                          Number.parseInt(e.currentTarget.value),
                        )
                      }
                      style={{width: '100%'}}
                    />
                  </div>
                  <div className="col-4">
                    <div className="input-group mb-3">
                      <input
                        type="number"
                        className="form-control"
                        value={controlSuccessRate}
                        onChange={e =>
                          setControlSuccessRate(
                            Number.parseFloat(e.currentTarget.value),
                          )
                        }
                      />
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                </div>
                <div className="row" style={{paddingTop: 10}}>
                  <div className="col-4">Test</div>
                  <div className="col-4">
                    <input
                      type="number"
                      className="form-control"
                      value={testSampleSize}
                      onChange={e =>
                        setTestSampleSize(
                          Number.parseInt(e.currentTarget.value),
                        )
                      }
                      style={{width: '100%'}}
                    />
                  </div>
                  <div className="col-4">
                    <div className="input-group mb-3">
                      <input
                        type="number"
                        className="form-control"
                        value={testSuccessRate}
                        onChange={e =>
                          setTestSuccessRate(
                            Number.parseFloat(e.currentTarget.value),
                          )
                        }
                      />
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                </div>
                <div className="row" style={{marginTop: 10}}>
                  <div className="col-4">Confidence</div>
                  <div className="col-8">
                    <div className="input-group mb-3">
                      <label
                        className="input-group-text"
                        htmlFor="inputGroupSelect01">
                        p =
                      </label>
                      <select
                        value={confidence}
                        onChange={e => setConfidence(e.currentTarget.value)}
                        className="form-select"
                        id="inputGroupSelect01">
                        <option>0.90</option>
                        <option selected>0.95</option>
                        <option>0.98</option>
                        <option>0.99</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <div className="card" style={{height: 300, width: '100%'}}>
              <div className="card-body">
                <h5 className="card-title">Confidence Intervals</h5>
                <div
                  id="graphElement"
                  style={{height: 200, width: '100%'}}></div>
                <h6 style={{textAlign: 'center'}}>
                  {(pvalue ? pvalue : 1) <
                  1 - Number.parseFloat(confidence ? confidence : '0.9')
                    ? 'The two populations differ significantly with p=' +
                      pvalue
                    : 'Differences between populations are NOT statistically significant.'}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="fb-comments"
            data-href="https://statsigcalculator.com/ztest-proportions"
            data-width="100%"
            data-numposts="10"></div>
        </div>
      </div>
    </>
  );
  return MainLayout(body());
};

export default Home;
