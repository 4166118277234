import {useEffect, useState} from 'react';
// @ts-ignore
import * as Plotly from 'plotly.js-dist';
import {Helmet} from 'react-helmet';
import MainLayout from './MainLayout';
// @ts-ignore
const {jStat} = require('jstat');

const Home = () => {
  const [testArray, setTestArray] = useState<number[]>([]);
  const [controlArray, setControlArray] = useState<number[]>([]);
  const [confidence, setConfidence] = useState('0.90');
  const [pvalue, setPvalue] = useState<number>();
  const [confidenceInterval1, setConfidenceInterval1] = useState<number>();
  const [confidenceInterval2, setConfidenceInterval2] = useState<number>();
  const zalpha2: any = {
    '0.90': 1.645,
    '0.95': 1.96,
    '0.98': 2.326,
    '0.99': 2.576,
  };

  const calculatePValue = async () => {
    const meanA = jStat.mean(controlArray);
    const meanB = jStat.mean(testArray);
    const S2 =
      (jStat.sum(jStat.pow(jStat.subtract(controlArray, meanA), 2)) +
        jStat.sum(jStat.pow(jStat.subtract(testArray, meanB), 2))) /
      (controlArray.length + testArray.length - 2);
    const t_score =
      (meanA - meanB) /
      Math.sqrt(S2 / controlArray.length + S2 / testArray.length);
    const t_pval =
      jStat.studentt.cdf(
        -Math.abs(t_score),
        controlArray.length + testArray.length - 2,
      ) * 2;

    setPvalue(t_pval);

    setConfidenceInterval1(
      zalpha2[confidence] *
        Math.sqrt(Math.pow(jStat.stdev(controlArray), 2) / controlArray.length),
    );
    setConfidenceInterval2(
      zalpha2[confidence] *
        Math.sqrt(Math.pow(jStat.stdev(testArray), 2) / testArray.length),
    );

    return Promise.resolve();
  };

  useEffect(() => {
    calculatePValue().then(() => {
      const controlMean = jStat.mean(controlArray);
      const testMean = jStat.mean(testArray);
      const lowerBounds = Math.min(
        controlMean - (confidenceInterval1 ? confidenceInterval1 : 0),
        testMean - (confidenceInterval2 ? confidenceInterval2 : 0),
      );
      const upperBounds = Math.max(
        controlMean + (confidenceInterval1 ? confidenceInterval1 : 0),
        testMean + (confidenceInterval2 ? confidenceInterval2 : 0),
      );

      const dataControl = {
        x: [controlMean],
        y: ['Control'],
        error_x: {
          type: 'data',
          array: [confidenceInterval1],
        },
        type: 'scatter',
      };

      const dataTest = {
        x: [testMean],
        y: ['Test'],
        error_x: {
          type: 'data',
          array: [confidenceInterval2],
        },
        type: 'scatter',
      };

      const layout = {
        xaxis: {
          range: [
            lowerBounds - 0.1 * Math.abs(lowerBounds - upperBounds),
            upperBounds + 0.1 * Math.abs(upperBounds - upperBounds),
          ],
          title: 'Mean Value',
          zeroline: false,
        },
        yaxis: {
          type: 'category',
          tickfont: {
            //size: 15,
          },
          ticklabeloverflow: 'allow',
          ticklabelposition: 'outside',
          showline: false,
          zeroline: false,
        },
        margin: {pad: 15, t: 25},
        showlegend: false,
      };

      Plotly.react('graphElement', [dataTest, dataControl], layout);
    });
  }, [
    controlArray,
    testArray,
    confidence,
    confidenceInterval1,
    confidenceInterval2,
  ]);

  function isNumeric(str: string) {
    if (typeof str !== 'string') {
      return false;
    } // we only process strings!
    return (
      !isNaN(Number(str)) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
  }

  const body = () => (
    <>
      <div className="container" style={{paddingTop: 50}}>
        <Helmet>
          <title>
            Stat Sig Calculator: Experiments w/ Population Proportions
          </title>
        </Helmet>
        <div className="row" style={{paddingBottom: 20}}>
          <div className="col-12">
            <h1>T-Test for Experiments with Means</h1>
            <h4>
              Use this template for testing the statistical significance between
              different experiment populations where the outcome metric you are
              measuring is an average value.
            </h4>
            <h6>Example: Average spend per customer.</h6>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <div className="card" style={{height: 400}}>
              <div className="card-body">
                <h5 className="card-title">Data Input</h5>
                <div className="row" style={{marginTop: 10}}>
                  <div className="col-3">Control</div>
                  <div className="col-9">
                    <div className="input-group">
                      <span className="input-group-text">
                        Sample Values
                        <br /> 1 per line
                      </span>
                      <textarea
                        style={{fontSize: 9}}
                        className="form-control"
                        rows={8}
                        onClick={e => e.currentTarget.select()}
                        onChange={e =>
                          setControlArray(
                            e.currentTarget.value
                              .split('\n')
                              .filter(a => isNumeric(a.trim()))
                              .map(a => Number.parseFloat(a.trim())),
                          )
                        }
                        //value={testArray.toString().replaceAll(',', '\n')}
                        aria-label="With textarea"></textarea>
                    </div>
                  </div>
                </div>
                <div className="row" style={{marginTop: 10}}>
                  <div className="col-3">Test</div>
                  <div className="col-9">
                    <div className="input-group">
                      <span className="input-group-text">
                        Sample Values
                        <br /> 1 per line
                      </span>
                      <textarea
                        style={{fontSize: 9}}
                        className="form-control"
                        rows={8}
                        onClick={e => e.currentTarget.select()}
                        onChange={e =>
                          setTestArray(
                            e.currentTarget.value
                              .split('\n')
                              .filter(a => isNumeric(a.trim()))
                              .map(a => Number.parseFloat(a.trim())),
                          )
                        }
                        //value={testArray.toString().replaceAll(',', '\n')}
                        aria-label="With textarea"></textarea>
                    </div>
                  </div>
                </div>
                <div className="row" style={{marginTop: 10}}>
                  <div className="col-3">Confidence</div>
                  <div className="col-9">
                    <div className="input-group mb-3">
                      <label
                        className="input-group-text"
                        htmlFor="inputGroupSelect01">
                        p =
                      </label>
                      <select
                        value={confidence}
                        onChange={e => setConfidence(e.currentTarget.value)}
                        className="form-select"
                        id="inputGroupSelect01">
                        <option>0.90</option>
                        <option selected>0.95</option>
                        <option>0.98</option>
                        <option>0.99</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <div className="card" style={{height: 300, width: '100%'}}>
              <div className="card-body">
                <h5 className="card-title">Confidence Intervals</h5>
                <div
                  id="graphElement"
                  style={{height: 200, width: '100%'}}></div>
                <h6 style={{textAlign: 'center'}}>
                  {(pvalue ? pvalue : 1) <
                  1 - Number.parseFloat(confidence ? confidence : '0.9')
                    ? 'The two populations differ significantly with p=' +
                      pvalue
                    : 'Differences between populations are NOT statistically significant.'}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="fb-comments"
            data-href="https://statsigcalculator.com/ttest-means"
            data-width="100%"
            data-numposts="10"></div>
        </div>
      </div>
    </>
  );
  return MainLayout(body());
};

export default Home;
