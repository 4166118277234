import './App.css';
import {Route, Routes} from 'react-router-dom';
import Home from './Home';
import ZTestProportions from './ZTestProportions';
import TTestMeans from './TTestMeans';

const App = () => {
  return (
    <main>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/ztest-proportions" element={<ZTestProportions />} />
        <Route path="/ttest-means" element={<TTestMeans />} />
      </Routes>
    </main>
  );
};

export default App;
