import {useNavigate} from 'react-router-dom';

const MainLayout = (body: any) => {
  const navigate = useNavigate();

  return (
    <>
      <nav className="navbar navbar-expand-lg bg-light">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a
                  className="nav-link"
                  aria-current="page"
                  href="/"
                  onClick={e => {
                    e.preventDefault();
                    navigate('/');
                  }}>
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  aria-current="page"
                  href="/ztest-proportions"
                  onClick={e => {
                    e.preventDefault();
                    navigate('/ztest-proportions');
                  }}>
                  Experiments w/ Percent Metrics
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  aria-current="page"
                  href="/ttest-means"
                  onClick={e => {
                    e.preventDefault();
                    navigate('/ttest-means');
                  }}>
                  Experiments w/ Means
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {body}
    </>
  );
};

export default MainLayout;
