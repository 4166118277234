import {Helmet} from 'react-helmet';
import MainLayout from './MainLayout';
import {useNavigate} from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();
  const body = () => (
    <>
      <div className="container" style={{paddingTop: 50}}>
        <Helmet>
          <title>Stat Sig Calculator: Analyze Experimental Results</title>
        </Helmet>
        <div className="row" style={{paddingBottom: 20}}>
          <div className="col-12">
            <h1>Statistical Analysis Tools for Experiment Results</h1>
            <h5>
              Your confidence in experimental results should relate to the
              statistical significance of the results - sometimes surprising
              results are just not statistically significant. Use these tools to
              help you determine whether the results can be relied upon.
            </h5>
          </div>
        </div>
        <div className="row">
          <div className="list-group">
            <button
              onClick={() => navigate('/ztest-proportions')}
              type="button"
              className="list-group-item list-group-item-action"
              aria-current="true">
              <div className="fw-bold">
                Z-Test for Experiments with Proportions
              </div>
              Use this when the outcome metric is a proportion or % of
              population with a trait.
            </button>
            <button
              onClick={() => navigate('/ttest-means')}
              type="button"
              className="list-group-item list-group-item-action"
              aria-current="true">
              <div className="fw-bold">T-Test for Experiments with Means</div>
              Use this when the outcome metric is an average value.
            </button>
          </div>
        </div>
      </div>
    </>
  );
  return MainLayout(body());
};

export default Home;
